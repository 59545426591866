'use-client'

import { useCallback } from 'react';
import qs from 'qs';

const useForgotPasswordService = () => {

    const endpoint = process.env.API_FORGOT_PASSWORD_ENDPOINT;


    const forgotPassword = useCallback(async (credentials) => {

        try {
            const data = qs.stringify({
                'send_forgotten_password': 'true',
                'forgot_password_identity': credentials.username,
            });

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
            });

            const responseData = await response.json();
            return responseData.success;

        } catch (error) {
            console.error('Password reset failed:', error);
            throw error;
        }
    }, [endpoint]);

    return { forgotPassword };
};

export default useForgotPasswordService;
