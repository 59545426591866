'use-client'



const useHandleAuthCodeService = () => {
    const endpoint = process.env.API_ACCESS_TOKEN_ENDPOINT;

    const handleAuthCode = async (provider, authCode) => {

        try {
            const response = await fetch(`${endpoint}/${provider}`, {
                method: "POST",
                body: authCode,
            })

            const data = await response.json()
            if (data.success === false) {
                return {
                    success: false,
                    message: data.message,
                    sessId: null,
                }
            }

            return {
                success: data.success,
                sessId: data.result.sess_id,
            }
        } catch (error) {
            console.error('AuthCode failed:', error);
            return {
                success: false,
                message: "AuthCode failed",
                sessId: null,
            }
        }

    }

    return { handleAuthCode };
};

export default useHandleAuthCodeService;



