'use-client'

const useCheckDocumentService = () => {

    const endpoint = process.env.API_CHECK_DOCUMENT_ENDPOINT;


    const isDocumentTaken = async (document) => {
        const headers = {
            'Content-Type': 'application/json; charset=UTF-8',
        };

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ value: document }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            return {
                isValid: data.isValid,
                message: data.message,
            };

        } catch (error) {
            console.error('Unable to get user:', error);
            throw error;
        }
    };

    return { isDocumentTaken };
};

export default useCheckDocumentService;
