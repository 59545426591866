'use-client'


import { Providers } from '@dsi-arena/frontend-react-framework';
import { phoneToDSI } from './parsePhoneNumber';
import qs from 'qs';

const usePersonalInformationService = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();

    const endpoint = process.env.API_UPDATE_PROFILE_ENDPOINT;

    const save = async (input) => {
        try {
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Sess-Id': sessId,
            };
            const data = qs.stringify(input);

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    ...headers,
                },
                body: data,
            });

            const responseData = await response.json();
            const success = responseData.success;
            const newSessId = responseData.result ? responseData.result.id : null;
            return {
                success: success,
                sessId: newSessId,
            };

        } catch (error) {
            console.error('Personal information update failed:', error);
            throw error;
        }
    };

    return { save };
};

export default usePersonalInformationService;


export const userToUpdatePersonalInformationInput = (data) => {
    const { countryPhoneCode, areaCode, phone } = phoneToDSI(data.phone);

    const userObj = {
        upro_nome_completo: data.name,
        upro_nr_doc_fis: data.document.replace(/[^a-zA-Z0-9]/g, ''),
        uacc_email: data.email,
        uacc_username: data.document.replace(/\D/g, ''),
        upro_dt_nasc: data.birthDate,
        upro_sexo: data.gender === "Male" ? "M" : "F",
        upro_fone_ddi: countryPhoneCode.replace(/\D/g, ''),
        upro_fone_ddd: areaCode.replace(/\D/g, ''),
        upro_fone_principal: phone.replace(/\D/g, ''),
        upro_nr_cep: data.zipCode.replace(/[^a-zA-Z0-9]/g, ''),
        upro_nm_rua: data.street,
        upro_numero_end: data.number,
        upro_des_complemento: data.complement,
        upro_nm_bairro: data.neighborhood,
        upro_nm_cidade: data.city,
        upro_sigla_uf: data.state,
        upro_nm_pais: data.country,
    };

    return userObj;
};

