'use-client'

'use-strict'
import { Models } from '@dsi-arena/frontend-react-framework';

const responseToOrdersHistory = (data) => {
    const { Order } = Models;
    let output = [];

    for (let i = 0; i < data.length; i++) {
        // Create a new instance of Order
        const order = new Order();

        // Explicitly set each property on the User instance
        order.id = data[i].pedido_id;
        order.itemId = data[i].pedidoitem_id;
        order.status = data[i].status;
        order.date = data[i].dataCompra;
        order.description = `${data[i].descItem} - ${data[i].obsItem} `;
        order.value = parseInt(data[i].vlTotal * 100);

        output.push(order);
    }

    return output;
}

export default responseToOrdersHistory;