'use-client'

import { phoneToDSI } from '../personalInformation/parsePhoneNumber';
import qs from 'qs';

const useRegisterService = () => {

    const endpoint = process.env.API_REGISTER_ENDPOINT;

    const save = async (input) => {
        try {
            const data = qs.stringify(input);

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: data,
            });

            const responseData = await response.json();
            const success = responseData.success;
            const newSessId = responseData.result ? responseData.result.sess_id : null;
            return {
                success: success,
                sessId: newSessId,
            };

        } catch (error) {
            console.error('Personal information update failed:', error);
            throw error;
        }
    };

    return { save };
};

export default useRegisterService;

export const userToRegisterAccountInput = (data, config) => {
    const { countryPhoneCode, areaCode, phone } = phoneToDSI(data.phone);

    const userObj = {
        nome: data.name,
        email: data.email,
        // username: config.UsernameField === "document" ? data.document : data.email,
        cpf: data.document ? data.document.replace(/[^a-zA-Z0-9]/g, '') : "",
        documento: data.document ? data.document.replace(/[^a-zA-Z0-9]/g, '') : "",
        senha: data.password,
        confsenha: data.confirmPassword,
        dtNasc: formatBirthDate(data.birthDate),
        upro_sexo: data.gender === "Male" ? "M" : "F",
        ddi: countryPhoneCode.replace(/\D/g, ''),
        ddd: areaCode.replace(/\D/g, ''),
        celular: phone.replace(/\D/g, ''),
        upro_nr_cep: data.zipCode.replace(/[^a-zA-Z0-9]/g, ''),
        upro_nm_rua: data.street,
        upro_numero_end: data.number,
        upro_des_complemento: data.complement,
        upro_nm_bairro: data.neighborhood,
        upro_nm_cidade: data.city,
        upro_sigla_uf: data.state,
        upro_nm_pais: data.country,
    };

    return userObj;
};

function formatBirthDate(birthDate) {
    // Assuming birthDate is in the format yyyy-mm-dd
    var dateParts = birthDate.split("-");
    var year = dateParts[0];
    var month = dateParts[1];
    var day = dateParts[2];

    // Returning the date in the format ddmmyyyy
    return day + month + year;
}
