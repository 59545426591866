'use-client'


import { Providers } from '@dsi-arena/frontend-react-framework';
import ResponseToOrdersHistory from './responseToOrdersHistory';

const useGetOrdersHistoryService = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();

    const endpoint = process.env.API_ORDERS_HISTORY_ENDPOINT;


    const getOrderHistory = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'X-Sess-Id': sessId,
        };

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.success) {
                return {
                    success: true,
                    orderHistory: ResponseToOrdersHistory(data.data),
                };
            }

            return {
                success: false,
                orderHistory: null,
            };

        } catch (error) {
            console.error('Unable to get users order history:', error);
            throw error;
        }
    };

    return { getOrderHistory };
};

export default useGetOrdersHistoryService;
