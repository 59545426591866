'use-client'

const useFetchAddressByZipCode = () => {

    const services = {
        BR: async (zipCode, setIsLoading) => {
            if (zipCode.length !== 8) {
                return null;
            }

            try {
                setIsLoading(true);
                const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`, {
                    method: 'GET',
                });
                setIsLoading(false);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                if (data.erro) {
                    return {
                        success: false,
                        street: "",
                        city: "",
                        state: "",
                        neighborhood: "",
                        country: "",
                    };
                }

                return {
                    success: true,
                    street: data.logradouro,
                    city: data.localidade,
                    state: data.uf,
                    neighborhood: data.bairro,
                };

            } catch (error) {
                console.error('Error getting address:', error);
                throw error;
            }
        },

        US: async (zipCode, setIsLoading) => {
            if (zipCode.length !== 5) {
                return null;
            }
            // console.log('US zip code service not implemented yet');
            return null;
        },

        AR: async (zipCode, setIsLoading) => {
            if (zipCode.length !== 8) {
                return null;
            }

            // console.log('AR zip code service not implemented yet');
            return null;
        },
    };

    const getZipCodeServiceByCountry = (country) => {
        return services[country] ? services[country] : null;
    }

    return { getZipCodeServiceByCountry };
};

export default useFetchAddressByZipCode;
