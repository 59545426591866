'use-client'

class esAR {
    Sentences = {
        "FAQ": "Preguntas Frecuentes",
        "TalkWithUs": "Contáctenos",
        "Back": "Volver",
        "Sector": "Sector",
        "NoSectorsAvailable": "No hay sectores disponibles",
        "ChooseYourSector": "Elija su sector",
        "NoCategoriesAvailable": "No hay categorías disponibles",
        "ChooseYourCategory": "Elija su categoría",

        "MyTickets": "Mis Entradas",
        "Search": "Buscar",
        "OpenQrCode": "Mostrar Código de Entrada",
        "TicketCode": "Código de Entrada",
        "ShareVoucher": "Compartir",
        "DownloadVoucher": "Descargar",
        "CheckoutSuccessInstructions": "¡Su pedido fue realizado con éxito! Sus entradas están disponibles en la pestaña 'Mis Entradas', dentro de 'Mi Cuenta'. Más detalles también fueron enviados al correo electrónico registrado.",

        "FAQ1-title": "¿Dónde puedo cambiar mis entradas?",
        "FAQ1-content": "Siempre que haya necesidad de cambiar las entradas, es crucial que el aficionado esté al tanto de que debe dirigirse siempre al canal donde se efectuó la compra con al menos 24 horas de anticipación. En caso de comprar las entradas a través del sitio web, debe enviar un correo electrónico con un mínimo de 24 horas de anticipación al juego a entradas@chapecoense.com",

        "FAQ2-title": "¿EN QUÉ CASOS PUEDO CAMBIAR MIS ENTRADAS?",
        "FAQ2-content": "Los cambios de entradas sólo ocurrirán cuando se compruebe la compra de las mismas mediante la presentación de los tickets o el número de pedido con la comprobación de compra exitosa (Pagado). Si el aficionado compró en el sector incorrecto: debe acudir al lugar donde compró la entrada (Punto de Venta o Taquilla) y simplemente solicitar el cambio de entradas entregando la antigua y recibiendo una nueva con el sector modificado. En casos de diferencias de precio: Si el valor del cambio es mayor, es decir, cuando hay un incremento, el aficionado debe pagar la diferencia, y si la diferencia es menor, se realizará el cambio y reembolso de la diferencia. En el caso de compras por internet: es necesario que el comprador envíe el número de pedido o entrada virtual al correo: entradas@chapecoense.com con al menos 24 horas de anticipación al juego para solicitar el cambio, explicando los motivos y el porqué del cambio de sector. Si la persona que va al juego no es la misma que compró la entrada online: En casos donde la compra online es nominal e intransferible, si el aficionado no puede asistir al estadio, debe enviar, con al menos 24 horas de anticipación, copias de los documentos con foto de la persona que lo sustituirá, así como nombre y DNI, al correo: entradas@chapecoense.com IMPORTANTE: Todos los procesos solicitados deben efectuarse con al menos 24 horas de anticipación al juego y serán atendidos y respondidos hasta 12 horas antes del juego. Las solicitudes fuera de plazo están sujetas a que no sean cambiadas. Los cambios se realizarán siempre que el sector al que el aficionado desee no esté agotado. Chapecoense se reserva el derecho de informar o no la capacidad de los sectores, así como el cambio o no de entradas.",

        "FAQ3-title": "¿CUÁNDO Y CÓMO SOLICITAR MI REEMBOLSO?",
        "FAQ3-content": "Todas las solicitudes de reembolso seguirán el derecho del consumidor y serán analizadas junto con el departamento legal del club para que el aficionado pueda tener la mejor experiencia posible. Las solicitudes de reembolso deben realizarse a través del correo: entradas@chapecoense.com",

        "FAQ4-title": "COMPRÉ UNA ENTRADA PERO NO PODRÉ ASISTIR AL EVENTO. ¿QUÉ HAGO?",
        "FAQ4-content": "Vaya al mostrador de venta de entradas con al menos 24 horas de anticipación al evento y solicite la cancelación de su compra. NOTA: Las ventas por Internet están sujetas a otras solicitudes de cancelación.",

        "FAQ5-title": "¿Y SI PIERDO LA ENTRADA QUE COMPRÉ E IMPRIMÍ?",
        "FAQ5-content": "Haga el login en su cuenta usando el DNI y contraseña registrados. Después de iniciar sesión, haga clic en 'Mis Compras'. Allí encontrará una lista de todas las entradas que ha comprado con la opción de reimprimirlas. Reimprima la entrada del evento deseado y preséntela en el mostrador de canje de voucher en la taquilla del estadio.",

        "FAQ6-title": "COMPRÉ UNA ENTRADA ANTICIPADA POR INTERNET Y LUEGO CAMBIARON EL PRECIO DE VENTA. ¿QUÉ PUEDO HACER?",
        "FAQ6-content": "Puede ingresar normalmente con la entrada que compró por internet. Si lo prefiere, también puede ir al mostrador de atención y venta de entradas y solicitar el ajuste de precio practicado por el club.",

        "ContactUs": "Contáctenos",
        "BeforeCalling": "Antes de llamar...",
        "HaveYouSeen": "¿Has verificado si tu pregunta ya ha sido respondida en nuestra sección de",
        "FrequentlyAskedQuestions": "Preguntas Frecuentes",
        "CallTheServiceCenter": "Llama al centro de atención:",
        "SendAnEmail": "O envía un correo electrónico a",
    }
}

const output = new esAR();
export default output;
