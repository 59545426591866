'use-client'

const usePagarmeCardToken = () => {
    const getCardToken = async (input) => {
        const apiCardTokenEndpoint = process.env.API_PAGARME_CARD_TOKEN_ENDPOINT;
        const apiKeyPagarme = process.env.API_PAGARME_APP_ID;
        const headers = {
            'content-type': 'application/json',
        }

        const pagarMeInput = {
            "type": "card",
            "card": {
                "number": input.creditCardNumber.replaceAll(" ", ""),
                "holder_name": input.name,
                "exp_month": input.creditCardExpirationDate.split('/')[0],
                "exp_year": input.creditCardExpirationDate.split('/')[1],
                "cvv": input.creditCardCvv,
                "billing_address": {
                    "line_1": `${input.street}, ${input.number}`,
                    "line_2": `${input.complement} - bairro: ${input.neighborhood}`,
                    "zip_code": input.zipCode,
                    "city": input.city,
                    "state": input.state,
                    "country": "brazil"
                }
            }
        }

        try {
            const response = await fetch(apiCardTokenEndpoint + "?appId=" + apiKeyPagarme,
                {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(pagarMeInput),
                }
            );

            const responseObj = await response.json();
            if (!responseObj.id) {
                return {
                    success: false,
                    error: responseObj.message + " - " + JSON.stringify(responseObj.errors),
                    data: {}
                };
            }

            return {
                success: true,
                data: responseObj.id,
            };

        } catch (error) {
            console.error("Error getting credit card token:", error);
            throw error;
        }
    };

    return { getCardToken };
};

export default usePagarmeCardToken;