'use-client'

import { Providers } from '@dsi-arena/frontend-react-framework';

const useAddCartItem = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();
    const addCartItem = async (item) => {
        if (!sessId) {
            throw new Error('Session ID is not available');
        }

        const input = {
            value: item.value,
            fee: item.fee,
            title: item.title,
            description: item.description,
            ingressocategoria_id: item.meta.ingressocategoria_id,
            metadata: item.meta,
        }

        const apiKey = process.env.DSI_API_KEY;
        const apiAddCartItemEndpoint = process.env.API_ADD_CART_ITEM_ENDPOINT;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
            'X-Sess-Id': sessId,
        }

        try {
            const response = await fetch(apiAddCartItemEndpoint,
                {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(input)
                }
            );

            const responseObj = await response.json();
            if (!responseObj.success) {
                return {
                    success: responseObj.success,
                    message: responseObj.error,
                };
            }

            return {
                success: true,
            };

        } catch (error) {
            console.error("Error adding item to cart:", error);
            throw error;
        }
    };

    return { addCartItem };
};

export default useAddCartItem;