'use-client'

import { Providers } from '@dsi-arena/frontend-react-framework';

const useLogoutService = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();

    const endpoint = process.env.API_LOGOUT_ENDPOINT;


    const logout = async (credentials) => {
        const headers = {
            'Content-Type': 'application/json',
            'X-Sess-Id': sessId,
        };

        try {

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    ...headers,
                },
            });

            const responseData = await response.json();
            return responseData;

        } catch (error) {
            console.error('Logout failed:', error);
            throw error;
        }
    };

    return { logout };
};

export default useLogoutService;
