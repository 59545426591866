'use-client'


import { Providers } from '@dsi-arena/frontend-react-framework';
import qs from 'qs';

const useUpdatePasswordService = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();

    const endpoint = process.env.API_UPDATE_PASSWORD_ENDPOINT;

    const save = async (input) => {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'X-Sess-Id': sessId,
        };

        try {
            const data = qs.stringify(input);

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    ...headers,
                },
                body: data,
            });

            const responseData = await response.json();
            const success = responseData.success;
            const message = (responseData.success === false && responseData.result) ? responseData.result : "";
            return {
                success: success,
                message: message,
            };

        } catch (error) {
            console.error('Password update failed:', error);
            throw error;
        }
    };

    return { save };
};

export default useUpdatePasswordService;
