'use-client'

import React from 'react';
import { Models } from '@dsi-arena/frontend-react-framework';

export const GetAgreements = () => {
    const { Agreement } = Models;
    return [
        new Agreement({
            id: 1,
            title: "Política de privacidade",
            content: "Lorem ipsum dolor sit amet.",
            requiredToPurchase: true,
            fixedOnFooter: true
        }),
        new Agreement({
            id: 2,
            title: "Política de ingressos",
            content: <div>asdasdasd</div>,
            requiredToPurchase: true,
            fixedOnFooter: true
        })
    ];
}