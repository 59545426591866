'use-client'

import { useCallback } from 'react';

import qs from 'qs';

const useLoginService = () => {
    const endpoint = process.env.API_LOGIN_ENDPOINT;


    const login = useCallback(async (credentials) => {

        try {
            const data = qs.stringify({
                'login': 'true',
                'login_identity': credentials.username,
                'login_password': credentials.password,
            });

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: data,
            });

            const responseData = await response.json();

            if (responseData.success) {
                return {
                    success: true,
                    sessId: responseData.result.sess_id,
                    message: responseData.message,
                };
            }
            return {
                success: false,
                sessId: null,
                message: responseData.message,
            };

        } catch (error) {

            console.error('Login failed:', error);
            throw error;
        }
    }, [endpoint]);

    return { login };
};

export default useLoginService;
