'use-client'

class enUS {
    Sentences = {
        "FAQ": "Frequently Asked Questions",
        "TalkWithUs": "Contact Us",
        "Back": "Back",
        "Sector": "Sector",
        "NoSectorsAvailable": "No sectors available",
        "ChooseYourSector": "Choose your sector",
        "NoCategoriesAvailable": "No categories available",
        "ChooseYourCategory": "Choose your category",

        "MyTickets": "My Tickets",
        "Search": "Search",
        "OpenQrCode": "Display Ticket Code",
        "TicketCode": "Ticket Code",
        "ShareVoucher": "Share",
        "DownloadVoucher": "Download",
        "CheckoutSuccessInstructions": "Your order was successfully completed! Your tickets are available in the 'My Tickets' tab within 'My Account'. Further details were also sent to the registered email.",

        "FAQ1-title": "Where can I exchange my tickets?",
        "FAQ1-content": "Whenever there is a need to exchange tickets, it is crucial for the fan to be aware that they should always address the exchange through the channel where the tickets were purchased, at least 24 hours in advance. In cases where the fan has purchased their tickets through the website, they should send an email at least 24 hours before the game to tickets@chapecoense.com",

        "FAQ2-title": "UNDER WHAT CONDITIONS CAN I EXCHANGE MY TICKETS?",
        "FAQ2-content": "Ticket exchanges will only occur when the purchase of the tickets is proven through the presentation of the ticket or the order number with proof of successful purchase (Paid). If a fan purchased in the wrong sector: they should go to the place where they purchased the ticket (Sales Point or Box Office) and simply request to change the tickets by handing over the old ticket and receiving a new one with the sector changed. In cases where price differences occur: If the change value is higher, meaning when there is an addition, it is necessary for the fan to pay the difference, and if the difference is lower, the exchange and refund of the difference will be made. In cases of online purchase: the buyer needs to send the order number or virtual ticket to the email: tickets@chapecoense.com at least 24 hours before the game to request the exchange, explaining the reasons and why the sector change is necessary. If the person at the game is not the same as the online ticket: In cases where the online purchase is nominal and non-transferable, if the fan cannot attend the stadium, they must send, at least 24 hours in advance, copies of documents with a photo of the person who will replace them, as well as name and CPF, to the email: tickets@chapecoense.com IMPORTANT: All requested processes must be carried out at least 24 hours before the game and will be attended to and returned within up to 12 hours before the game. Requests outside this period are subject to not being exchanged. The exchanges will be made as long as the desired sector by the fan does not have its capacity exhausted. Chapecoense reserves the right to inform or not the capacity of the sectors, as well as the exchange or not of the tickets.",

        "FAQ3-title": "WHEN AND HOW DO I REQUEST MY REFUND?",
        "FAQ3-content": "All refund requests will follow consumer rights and will be analyzed together with the club's legal team so that the fan can have the best possible experience. Refund requests should be made through the email: tickets@chapecoense.com",

        "FAQ4-title": "I BOUGHT A TICKET BUT WILL NOT BE ABLE TO ATTEND THE EVENT. WHAT DO I DO?",
        "FAQ4-content": "Go to the ticket sales counter at least 24 hours before the event and request the cancellation of your purchase. NOTE: Online sales are subject to other cancellation requests.",

        "FAQ5-title": "WHAT IF I LOSE THE TICKET I BOUGHT AND PRINTED?",
        "FAQ5-content": "Log in to your account using the registered CPF and password. After logging in, click on 'My Purchases'. There you will find a list of all tickets you have purchased with the option to reprint them. Reprint the desired event ticket and present it at the voucher exchange counter at the stadium box office.",

        "FAQ6-title": "I BOUGHT A TICKET IN ADVANCE ONLINE AND THEN THE SELLING PRICE CHANGED. WHAT CAN I DO?",
        "FAQ6-content": "You can normally enter with the ticket you purchased online. If you prefer, you can also go to the customer service and ticket sales counter and request the price adjustment practiced by the club.",

        "ContactUs": "Contact Us",
        "BeforeCalling": "Before calling...",
        "HaveYouSeen": "Have you checked if your question has already been answered in our",
        "FrequentlyAskedQuestions": "Frequently Asked Questions section",
        "CallTheServiceCenter": "Call the service center:",
        "SendAnEmail": "Or send an email to",
    }
}

const output = new enUS();
export default output;
