'use-client'



const useHandleJwtService = () => {
    const endpoint = process.env.API_JWT_ENDPOINT;

    const handleJwt = async (user) => {
        const jwt = user.credential;
        const response = await fetch(endpoint, {
            method: "POST",
            body: jwt,
        })
        const data = await response.json()
        return {
            success: data.success,
            sessId: data.result.sess_id,
        }
    }

    return { handleJwt };
};

export default useHandleJwtService;



