'use-client'

import { Providers } from '@dsi-arena/frontend-react-framework';

const useCheckout = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();
    const checkout = async (input) => {
        const apiKey = process.env.DSI_API_KEY;
        const apiCheckoutEndpoint = process.env.API_CHECKOUT_ENDPOINT;
        const headers = {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
            'X-Sess-Id': sessId,
        }

        const dsiLoggedCheckoutInput = {
            card_token: input.card_token,
            formapagto_id: input.paymentMethodId,
            document: input.document ? input.document.replace(/[^a-zA-Z0-9]/g, '') : "",
        }

        try {
            const response = await fetch(apiCheckoutEndpoint,
                {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(dsiLoggedCheckoutInput),
                }
            );

            const responseObj = await response.json();
            if (!responseObj.success || responseObj.data.length === 0) {
                return {
                    success: responseObj.success,
                    error: responseObj.error,
                    data: {}
                };
            }

            return {
                success: true,
                data: {
                    pixCode: responseObj.data.codePix,
                    pixQrCodeImageUrl: responseObj.data.linkPix,
                },
            };

        } catch (error) {
            console.error("Error checking out:", error);
            throw error;
        }
    };

    return { checkout };
};

export default useCheckout;