'use-client'

import LoginResponseToUser from './loginResponseToUser';

const useGetUserService = () => {
    const endpoint = process.env.API_PROFILE_ENDPOINT;

    const getUser = async (sessId) => {
        if (!sessId) {
            return;
        }

        if (!sessId) {
            // Handle case when sessId is not available
            throw new Error('Session ID is not available');
        }

        const headers = {
            'Content-Type': 'application/json',
            'X-Sess-Id': sessId,
        };

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.success) {
                return {
                    success: true,
                    userObj: LoginResponseToUser(data.result),
                    sessId: sessId,
                };
            }

            return {
                success: false,
                userObj: null,
                sessId: null,
            };

        } catch (error) {
            console.error('Unable to get user:', error);
            throw error;
        }
    };

    return { getUser };
};

export default useGetUserService;

