'use-strict'

import { DSIToPhone } from '../personalInformation/parsePhoneNumber';
import { Models } from '@dsi-arena/frontend-react-framework';

const loginResponseToUser = (data) => {
    const { UserAuthInfos } = Models;

    const userObj = new UserAuthInfos();

    const countryCode = data.upro_nm_pais ? data.upro_nm_pais : "BR"

    const phone = DSIToPhone({ userCountry: data.upro_nm_pais, countryPhoneCode: data.upro_fone_ddi, areaCode: data.upro_fone_ddd, phone: data.upro_fone_principal });

    // Explicitly set each property on the User instance
    userObj.name = data.upro_nome_completo;
    userObj.email = data.uacc_email;
    userObj.document = data.upro_nr_doc_fis;
    userObj.birthDate = data.upro_dt_nasc;
    userObj.gender = data.upro_sexo === "M" ? "Male" : "Female";
    userObj.phone = phone;
    userObj.zipCode = data.upro_nr_cep;
    userObj.street = data.upro_nm_rua;
    userObj.number = data.upro_numero_end;
    userObj.complement = data.upro_des_complemento;
    userObj.neighborhood = data.upro_nm_bairro;
    userObj.city = data.upro_nm_cidade;
    userObj.state = data.upro_sigla_uf;
    userObj.country = countryCode;

    return userObj;
}

export default loginResponseToUser;