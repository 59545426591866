'use-client'

import { Providers, Models } from '@dsi-arena/frontend-react-framework';


const useGetCartItems = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();

    const getCartItems = async (item) => {
        if (!sessId) {
            throw new Error('Session ID is not available');
        }

        const apiKey = process.env.DSI_API_KEY;
        const apiGetCartItemsEndpoint = process.env.API_GET_CART_ITEMS_ENDPOINT;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
            'X-Sess-Id': sessId,
        }

        try {
            const response = await fetch(apiGetCartItemsEndpoint,
                {
                    method: 'GET',
                    headers: headers,
                }
            );

            const responseObj = await response.json();

            if (!responseObj.success || responseObj.data.length === 0) {
                // console.error("Error in getting cart items request:", responseObj);
                return {
                    success: responseObj.success,
                    data: [],
                    message: responseObj.error,
                };
            }

            return {
                success: true,
                data: CartItemsResponseToCartItems(responseObj.data), //cart items response to cart items
                message: responseObj.message,
            };

        } catch (error) {
            console.error("Error getting cart items:", error);
            throw error;
        }
    };

    return { getCartItems };
};

export default useGetCartItems;

const CartItemsResponseToCartItems = (cartItemsResponse) => {
    const { CartItem } = Models;
    if (!Array.isArray(cartItemsResponse)) {
        // throw new TypeError('Expected an array for cartItemsResponse');
        return [];
    }

    let output = [];
    cartItemsResponse.forEach((item) => {
        const input = new CartItem(item.description, item.value, item.fee);
        input.id = item.id;
        input.title = item.title;
        input.meta = item.metadata;
        output.push(input);
    });

    return output;
}