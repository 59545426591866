'use-client'

class ptBR {
    Sentences = {
        "FAQ": "Perguntas frequentes",
        "TalkWithUs": "Fale conosco",
        "Back": "Voltar",
        "Sector": "Setor",
        "NoSectorsAvailable": "Nenhum setor disponível",
        "ChooseYourSector": "Escolha seu setor",
        "NoCategoriesAvailable": "Nenhuma categoria disponível",
        "ChooseYourCategory": "Escolha sua categoria",

        "MyTickets": "Meus ingressos",
        "Search": "Pesquisar",
        "OpenQrCode": "Exibir código do ingresso",
        "TicketCode": "Código do ingresso",
        "ShareVoucher": "Compartilhar",
        "DownloadVoucher": "Baixar",
        "CheckoutSuccessInstructions": "Seu pedido foi realizado com sucesso! Seus ingressos estão disponíveis na aba 'Meus ingressos', dentro de 'Minha conta'. Mais informações também foram enviadas ao email cadastrado.",

        "FAQ1-title": "Onde posso trocar meus ingressos?",
        "FAQ1-content": "Sempre que houver necessidade de troca dos ingressos, é importantíssimo que o torcedor esteja ciente que deve se direcionar sempre no canal onde foi efetuada a compra dos ingressos, com antecedência mínima de 24h. Em casos que o torcedor tenha adquirido seus ingressos através do site, o mesmo deve encaminhar e-mail com no mínimo 24h de antecedência do jogo para ingressos@chapecoense.com",

        "FAQ2-title": "EM QUE CASOS POSSO TROCAR MEUS INGRESSOS?",
        "FAQ2-content": "As trocas de ingressos só ocorreram quando for comprovada a compra dos ingressos mediante apresentação do mesmo ou sob número de pedido com comprovação de compra efetuada com sucesso (Pago).Em caso que o torcedor efetuou a compra do setor incorreto: o torcedor deve comparecer no local onde ele efetuou a compra do ingresso (Ponto de Venda ou Bilheteria) e apenas solicitar a alteração dos ingressos, entregando o ingresso antigo e recebendo novo com setor alterado.Em casos que houver diferenças de Valores: Caso o Valor da troca seja a maior, ou seja, quando houver acréscimo, faz-se necessário que o torcedor pague a diferença, e já em casos a diferença for a menor, será efetuada a troca e reembolso da diferença.Caso seja efetuada compra pela internet: é necessário que o comprador encaminhe número do pedido ou ingresso virtual para o e-mail: ingressos@chapecoense.com com uma antecedência de 24h antes do jogo para solicitar a troca do ingresso, relatando os motivos e o porquê da troca de setor.Caso a pessoa que no jogo não for a mesma do ingresso online: Nos casos em que a compra do ingresso online, ao qual é nominal e intransferível, o torcedor não puder comparecer no estádio, ele deve encaminhar com antecedência mínima de 24 h, cópias dos documentos com foto da pessoa que irá substitui-lo, bem como nome e CPF, para o e-mail: ingressos@chapecoense.comIMPORTANTE: Todos os processos solicitados devem ser efetuados com antecedência de 24 h antes do jogo e serão atendidos e retornados em um prazo de até 12h antes do jogo. Solicitações fora do prazo, ficam sujeitas a não serem trocados. Também os processos serão realizados desde que o setor ao qual o torcedor desejar, não tenha sua capacidade esgotada.A Chapecoense reserva-se o direito de informar ou não a capacidade dos setores, bem como a troca ou não dos ingressos.",

        "FAQ3-title": "QUANDO E COMO PEDIR MEU REEMBOLSO?",
        "FAQ3-content": "Todos os reembolsos solicitados, seguirão o direito do consumidor, e serão analisados juntamente ao jurídico do clube, para que o torcedor possa ter a melhor experiência possível. As solicitações de reembolso devem ser efetuadas através do e-mail:ingressos@chapecoense.com",

        "FAQ4-title": "COMPREI O INGRESSO, MAS NÃO PODEREI COMPARECER AO EVENTO. O QUE EU FAÇO?",
        "FAQ4-content": "Vá até o guichê de venda de ingressos até 24h antes do evento e solicite o cancelamento da sua compra. OBS: Vendas pela Internet, estão sujeitas a outras solicitações de cancelamento.",

        "FAQ5-title": "E SE EU PERDER O INGRESSO QUE EU COMPREI E IMPRIMI?",
        "FAQ5-content": "Faça o login na sua retaguarda, utilizando o CPF e senha cadastrados. Após feito o login, clique em 'Minhas compras'. Lá irá ter uma lista de todos os ingressos que você já comprou com a opção de reimprimi-los. Reimprima o ingresso do evento desejado e apresente no guichê de troca de voucher na bilheteria do estádio.",

        "FAQ6-title": "COMPREI UM INGRESSO ANTECIPADO PELA INTERNET E LOGO DEPOIS MUDARAM O PREÇO DE VENDA. O QUE POSSO FAZER?",
        "FAQ6-content": "Você pode entrar normalmente com o ingresso que você comprou pela internet. Se preferir, também pode ir até ao guichê de atendimento e venda de ingressos e solicitar o reajuste praticado pelo clube.",

        "ContactUs": "Fale conosco",
        "BeforeCalling": "Antes de ligar...",
        "HaveYouSeen": "Você já viu se a sua dúvida já não foi respondida na nossa sessão de",
        "FrequentlyAskedQuestions": "Dúvidas frequentes",
        "CallTheServiceCenter": "Ligue na central de atendimento:",
        "SendAnEmail": "Ou mande um e-mail para",

    }
}

const output = new ptBR();
export default output;