'use-client'

import parsePhoneNumber from 'libphonenumber-js'

export const phoneToDSI = (input) => {
    const phoneNumber = parsePhoneNumber(input);

    let countryPhoneCode;
    let nationalNumber;
    let areaCode;
    let phone;

    if (phoneNumber) {
        if (!phoneNumber.isValid()) {
            // setErr({ message: Language.getTranslation('InvalidPhone') });
            // return;
        }

        countryPhoneCode = phoneNumber.countryCallingCode || '';
        nationalNumber = phoneNumber.nationalNumber || '';

        const areaCodeLength = getAreaCodeLength(countryPhoneCode);
        areaCode = nationalNumber.substring(0, areaCodeLength);
        phone = nationalNumber.substring(areaCodeLength, nationalNumber.length);
    }

    return {
        countryPhoneCode,
        areaCode,
        phone,
    };
}

export const getAreaCodeLength = (countryPhoneCode) => {
    const defaultAreaCodeLength = 2;
    return areaCodeLengthList[countryPhoneCode] || defaultAreaCodeLength;
};

export const DSIToPhone = ({ userCountry, countryPhoneCode, areaCode, phone }) => {
    let phoneObj;

    if (!countryPhoneCode) {
        if (!userCountry) {
            phoneObj = parsePhoneNumber(`${areaCode}${phone}`, 'BR');
        } else {
            phoneObj = parsePhoneNumber(`${areaCode}${phone}`, userCountry);
        }
    } else {
        phoneObj = parsePhoneNumber(`+${countryPhoneCode}${areaCode}${phone}`);
    }

    if (!phoneObj) {
        return "";
    }

    return phoneObj.formatInternational().replaceAll(" ", "");
}

const areaCodeLengthList = {
    '1': 3,
    '55': 2,
    '54': 2,
}
