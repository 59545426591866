'use-client'

import React from 'react';
import { Providers, Models } from '@dsi-arena/frontend-react-framework';

import TicketsFromUser from './ticketsFromUser';

export const GetExtraTabs = () => {
    const { Tab } = Models;
    const { useTranslating } = Providers;
    const { Language } = useTranslating();
    return [
        new Tab(Language.getTranslation("MyTickets"), <TicketsFromUser />),
    ];
};