'use-client'

import React from 'react';
import { Events, Providers } from '@dsi-arena/frontend-react-framework';

export const Event = ({ eventName, eventDate, eventTime, tickets }) => {
    return (
        <>
            <div className="collapse collapse-arrow bg-base-200 rounded-lg w-full outline outline-1 outline-slate-300 mb-4">
                <input type="checkbox" />
                <div className="collapse-title font-medium">{eventName} - {eventDate} {eventTime}</div>
                <div className="collapse-content">
                    {tickets.map(ticket => <TicketFromUser key={ticket.acesso_id} ticket={ticket} />)}
                </div>
            </div>
        </>
    );
}

export const TicketFromUser = ({ ticket }) => {
    const { useTranslating } = Providers;
    const { Language } = useTranslating();
    const { Emit } = Events;

    const getStatusClass = (status) => {
        switch (status) {
            case "Utilizado":
                return "primary";
            case "Em aberto":
                return "secondary";
            default:
                return "default";
        }
    };

    const statusClass = getStatusClass(ticket.status_acesso_str);

    return (
        <div key={ticket.acesso_id} className="indicator  p-5 rounded-lg w-full outline outline-1 outline-slate-400 outline-dashed shadow-xl bg-white mb-5">
            <span className={`indicator-item indicator-center badge badge-${statusClass}`}>{ticket.status_acesso_str}</span>
            <div className="flex lg:flex-row flex-col w-full">
                <div className="lg:w-2/3 w-full">
                    <p className="text-xs"><b>ID</b>: {ticket.acesso_id}</p>
                    {/* <p className="text-xs">Barcode: {ticket.barcode}</p> */}
                    <p className="text-xs">Categoria: {ticket.categoria}</p>
                    <p className="text-xs">Setor: {ticket.ingresso}</p>
                    <p className="text-xs">Origem: {ticket.pedido_origem_str}</p>
                </div>
                <div className="lg:w-1/3 w-full text-center">
                    <div className="pt-4">
                        <button
                            className="btn btn-xs btn-outline btn-primary"
                            disabled={ticket.status_acesso_str === "Utilizado"}
                            onClick={() => { Emit("ticketCodeModal.show", ticket) }}
                        >
                            {Language.getTranslation("OpenQrCode")} <i className="bi bi-box-arrow-up-right"></i>
                        </button>
                    </div>
                    {/* <div className="pt-4 join">
                        <button
                            className="btn join-item btn-xs btn-outline btn-primary"
                            disabled={ticket.status_acesso_str === "Utilizado"}
                        >
                            {Language.getTranslation("ShareVoucher")} <i className="bi bi-share-fill"></i>
                        </button>
                        <button
                            className="btn join-item btn-xs btn-outline btn-primary"
                            disabled={ticket.status_acesso_str === "Utilizado"}
                        >
                            {Language.getTranslation("DownloadVoucher")} <i className="bi bi-cloud-download"></i>
                        </button>
                    </div> */}

                </div>
            </div>

        </div>

    );
}