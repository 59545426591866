'use-client'


import { Providers } from '@dsi-arena/frontend-react-framework';

const useGetTicketsFromUser = () => {
    const { useAuth } = Providers;
    const { sessId } = useAuth();

    const getTicketsFromUser = async (item) => {
        if (!sessId) {
            throw new Error('Session ID is not available');
        }

        const apiKey = process.env.DSI_API_KEY;
        const apiGetTicketsFromUserEndpoint = process.env.API_GET_TICKETS_FROM_USER_ENDPOINT;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
            'X-Sess-Id': sessId,
        }

        try {
            const response = await fetch(apiGetTicketsFromUserEndpoint,
                {
                    method: 'GET',
                    headers: headers,
                }
            );

            const responseObj = await response.json();

            if (!responseObj.success || responseObj.data.length === 0) {
                return {
                    success: responseObj.success,
                    data: [],
                    message: responseObj.error,
                };
            }

            return {
                success: true,
                data: responseObj.data, // response to tickets from user
                message: responseObj.message,
            };

        } catch (error) {
            console.error("Error getting tickets from user:", error);
            throw error;
        }
    };

    return { getTicketsFromUser };
};

export default useGetTicketsFromUser;
