'use-client'

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const LogoImageComponent = ({ fileName }) => {

  const imageFilesQueryResult = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(width: 50, placeholder: NONE)
            }
          }
        }
      }
    }
  `);

  if (!imageFilesQueryResult) return null;

  const image = imageFilesQueryResult.allFile.edges.find(edge => edge.node.relativePath === fileName)?.node;

  if (!image) {
    return null; // or return a fallback image
  }

  const imageData = getImage(image.childImageSharp.gatsbyImageData);
  return <GatsbyImage image={imageData} alt="Logo" />;
};
