'use-client'


import { Models } from '@dsi-arena/frontend-react-framework';

const usePaymentMethods = () => {
    const { PaymentMethod } = Models;
    const getPaymentMethods = async () => {
        const apiKey = process.env.DSI_API_KEY;
        const apiPaymentMethodsEndpoint = process.env.API_PAYMENT_METHODS_ENDPOINT;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
        }

        try {
            const response = await fetch(apiPaymentMethodsEndpoint,
                {
                    method: 'GET',
                    headers: headers,
                }
            );

            const responseObj = await response.json();
            if (!responseObj.success || responseObj.data.length === 0) {
                return {
                    success: responseObj.success,
                    data: []
                };
            }

            const output = responseObj.data.map((paymentMethod) => {
                const { formapagto_id, descForma, tipo } = paymentMethod;
                const input = new PaymentMethod();
                input.id = formapagto_id;
                input.label = descForma;
                input.shouldOpenCreditCardModal = tipo === "CIELO_CRE" ? true : false;
                input.shouldOpenPixModal = tipo === "PIX" ? true : false;
                return input;
            });

            return {
                success: true,
                data: output
            };

        } catch (error) {
            console.error("Error fetching payment methods data:", error);
            throw error;
        }
    };

    return { getPaymentMethods };
};

export default usePaymentMethods;
